html, body {
  margin: 0;
  height: 100%;
  min-height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

header,
footer {
  flex: none;
}

main {
  flex: auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

body {

}

body > * {
  padding: 10px 15px;
}

header,
footer {
  text-align: center;
}

